@import url('https://fonts.googleapis.com/css2?family=Alata&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.5s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #c44b99;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

@layer base {
  h3 {
    @apply text-4xl font-bold font-alata  text-center mt-20 md:text-5xl;
  }
  h5 {
    @apply text-grayishBlue mt-4 text-center max-w-xs font-alata mx-auto md:max-w-3xl md:mb-4;
  }
}

.section-center {
  @apply mt-16 mb-16 max-w-md mx-auto font-sans md:max-w-2xl lg:max-w-5xl xl:max-w-6xl   lg:px-8;
}
.backimg {
  background: url('./Assets/Decore.png');
  background-size: contain;
  background-position: bottom;
}
